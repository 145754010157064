import { memo } from 'react';
import { NodeResizer } from '@xyflow/react';
import ActionButtons from './ActionButtons';

const AnnotationNode = ({ id, data }) => {
  return (
    <>
      <ActionButtons id={id} data={data} />
      <NodeResizer minWidth={100} minHeight={30} />
      <div style={{ padding: 10, wordBreak: 'break-all' }}>{data.label}</div>
    </>
  );
};

export default memo(AnnotationNode);
