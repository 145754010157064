import React, { useState } from 'react';
import { useNodes, useEdges, useReactFlow } from '@xyflow/react';
import { Undo } from 'lucide-react';
import { toast } from 'react-toastify';
// import { retryableAPICall } from '../../../../api/common-api-utils';
// import { saveATRUser } from '../../../../api/ATRAPI/ATRAPI';
// import { requestStatuses } from '../../../../js/constants/requestStatuses';
import { CreateButton, EditButton, IconButton } from '../../../Base/Buttons';
import SettingsSlider from './DragAndDrop/Sliders/SettingsSlider/SettingsSlider';
import Validation, { validateWorkflow, convertWorkflow } from './DragAndDrop/Validation';
import { Input } from 'reactstrap';
import styled from 'styled-components';

const StyledBackButtonContainer = styled.div`
  font-size: 14px;
  color: #1a2e56;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const StyledBackButton = styled.div`
  width: 20px;
  cursor: pointer;
  color: #1a2e56;
  margin-bottom: auto;
  margin-right: 6px;
  display: flex;
`;

function HeaderPanel({ goBackToEdit, initialWorkflow, setIsSaving, isSaving }) {
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [name, setName] = useState(initialWorkflow.name);
  const { setNodes, setEdges } = useReactFlow();

  const nodes = useNodes();
  const edges = useEdges();

  async function handleSave() {
    setIsSaving(true);

    const noderr = nodes;
    const edgerr = edges;
    debugger;
    const convertedWorkflow = convertWorkflow(currentWorkflow);

    setTimeout(() => {
      setIsSaving(false);
      toast.success(`Workflow successfully created`);
    }, 30000);
    // try {
    //   const resp = await retryableAPICall(() => console.log('saveATRUser'));

    //   if (typeof resp === 'string') {
    //     let reasonStr = '';
    //     if (isEditing) {
    //       if (resp === requestStatuses.NOT_FOUND_ERROR) {
    //         reasonStr = '. Could not update workflow';
    //       } else if (resp === requestStatuses.PERMISSION_DENIED) {
    //         reasonStr = '. You do not have permission to edit this workflow';
    //       }
    //     }

    //     if (resp === requestStatuses.ALREADY_EXISTS_ERROR) {
    //       reasonStr = '. Unable to create workflow as this name has already been used';
    //     }

    //     toast.error(`Error ${isEditing ? 'updating' : 'creating'} workflow${reasonStr}`);
    //   } else {
    //     toast.success(`Workflow successfully ${isEditing ? 'updated' : 'created'}`);
    //     onSave(resp);
    //   }
    // } catch (error) {
    //   console.error(error);
    //   toast.error(`Error ${isEditing ? 'updating' : 'creating'} workflow`);
    // } finally {
    //   setIsSaving(false);
    // }
  }

  const isDiff = JSON.stringify(initialWorkflow) !== JSON.stringify({ name, nodes, edges });
  const errors = validateWorkflow(nodes, edges);

  return (
    <>
      <StyledBackButtonContainer className="d-flex">
        <StyledBackButton
          onClick={() => {
            goBackToEdit();
          }}
        >
          <svg
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="ArrowBackIcon"
            fill="#1A2E56"
          >
            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z"></path>
          </svg>
        </StyledBackButton>
        <span>Back</span>
      </StyledBackButtonContainer>
      <div className="d-flex pb-3 justify-content-between w-100">
        <div className="d-flex gap-2">
          <h5 style={{ marginTop: '7px', marginRight: '10px' }}>Name:</h5>
          <Input
            id="name"
            onChange={(e) => setName(e.target.value)}
            type="text"
            value={name || ''}
            disabled={isSaving}
          />
        </div>
        <div className="d-flex gap-2">
          {isDiff ? (
            <a
              className="my-auto pt-2 me-2"
              onClick={() => {
                setNodes(initialWorkflow.nodes);
                setEdges(initialWorkflow.edges);
                setName(initialWorkflow.name);
              }}
              type="button"
              size={'small'}
              disabled={isSaving}
              loadingIconColor="white"
            >
              <Undo size={20} />
            </a>
          ) : null}
          <Validation errors={errors} />
          <IconButton
            className="mt-2"
            iconClassName="icon-settings m-auto"
            action={(e) => {
              e.preventDefault();
              setIsOptionsModalOpen(true);
            }}
          />
          <EditButton
            className="mt-2"
            floatRight={false}
            label={'Activate'}
            disabled={isSaving || errors.length > 0}
            action={() => console.log('Activate')}
          />
          <CreateButton
            className="mt-2"
            floatRight={false}
            label={'Save'}
            action={handleSave}
            disabled={isSaving || !isDiff}
          />
        </div>
      </div>
      <SettingsSlider
        isSliderOpen={isOptionsModalOpen}
        closeSlider={(e, updatedEdgesedges) => {
          setIsOptionsModalOpen(false);
        }}
        onSave={(value) => {
          setIsOptionsModalOpen(false);
        }}
        initialValues={{}}
      />
    </>
  );
}

export default HeaderPanel;
