import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RightToWork, InviteToInterview, ProcessNewCandidate } from './DragAndDrop/Templates';
import { PageColumn } from '../Dashboard';
import File from './DragAndDrop/Icons/File';
import CheckCircle from './DragAndDrop/Icons/CheckCircle';
import Event from './DragAndDrop/Icons/Event';
import User from './DragAndDrop/Icons/User';
import Clock from './DragAndDrop/Icons/Clock';
import CrossCircle from './DragAndDrop/Icons/CrossCircle';

const CardBox = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e5e5e5;
  height: 10rem;
  width: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  gap: 1rem;

  span {
    font-size: 14px;
    font-weight: 600;
  }
`;

const StyledBackButtonContainer = styled.div`
  font-size: 14px;
  color: #1a2e56;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const StyledBackButton = styled.div`
  width: 20px;
  cursor: pointer;
  color: #1a2e56;
  margin-bottom: auto;
  margin-right: 6px;
  display: flex;
`;

const Icon = styled.div`
  svg {
    margin: auto;
    fill: #0a1551;
    width: 42px !important;
    height: 42px !important;
  }
`;

function WorkflowCreatePanel({ goBackToEdit, onCreateWorkflow }) {
  return (
    <PageColumn className="pt-3 d-flex flex-column">
      <StyledBackButtonContainer className="d-flex">
        <StyledBackButton
          onClick={() => {
            goBackToEdit();
          }}
        >
          <svg
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="ArrowBackIcon"
            fill="#1A2E56"
          >
            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z"></path>
          </svg>
        </StyledBackButton>
        <span>Back</span>
      </StyledBackButtonContainer>
      <div>
        <h2>Choose a Template</h2>
      </div>
      <div className="pt-3 d-flex flex-column">
        <h5>Candidates</h5>
        <div className="pt-3 d-flex gap-3">
          <CardBox
            onClick={() => {
              onCreateWorkflow({ name: 'Blank Workflow', nodes: [], edges: [] });
            }}
          >
            <Icon>
              <File />
            </Icon>
            <span>Blank</span>
          </CardBox>
          <CardBox
            onClick={() => {
              onCreateWorkflow({ name: 'Blank Workflow', nodes: [], edges: [] });
              //onCreateWorkflow({ name: RightToWork.title, nodes: RightToWork.nodes, edges: RightToWork.edges });
            }}
          >
            <Icon>
              <CheckCircle />
            </Icon>
            <span>Right to work Failed</span>
          </CardBox>
          <CardBox
            onClick={() => {
              onCreateWorkflow({ name: 'Blank Workflow', nodes: [], edges: [] });
              // onCreateWorkflow({
              //   name: InviteToInterview.title,
              //   nodes: InviteToInterview.nodes,
              //   edges: InviteToInterview.edges,
              // });
            }}
          >
            <Icon>
              <Event />
            </Icon>
            <span>Invite to Interview</span>
          </CardBox>
          <CardBox
            onClick={() => {
              onCreateWorkflow({ name: 'Blank Workflow', nodes: [], edges: [] });

              // onCreateWorkflow({
              //   name: ProcessNewCandidate.title,
              //   nodes: ProcessNewCandidate.nodes,
              //   edges: ProcessNewCandidate.edges,
              // });
            }}
          >
            <Icon>
              <User />
            </Icon>
            <span>New Candidate</span>
          </CardBox>
        </div>
      </div>
      <div className="pt-3 d-flex flex-column">
        <h5>Vacancy</h5>
        <div className="pt-3 d-flex gap-3">
          <CardBox
            onClick={() => {
              onCreateWorkflow({ name: 'Blank Workflow', nodes: [], edges: [] });

              // onCreateWorkflow({
              //   name: ProcessNewCandidate.title,
              //   nodes: ProcessNewCandidate.nodes,
              //   edges: ProcessNewCandidate.edges,
              // });
            }}
          >
            <Icon>
              <File />
            </Icon>
            <span>Blank</span>
          </CardBox>
          <CardBox
            onClick={() => {
              onCreateWorkflow({ name: 'Blank Workflow', nodes: [], edges: [] });

              // onCreateWorkflow({
              //   name: ProcessNewCandidate.title,
              //   nodes: ProcessNewCandidate.nodes,
              //   edges: ProcessNewCandidate.edges,
              // });
            }}
          >
            <Icon>
              <Clock />
            </Icon>
            <span>Expired Vacancy</span>
          </CardBox>
          <CardBox
            onClick={() => {
              onCreateWorkflow({ name: 'Blank Workflow', nodes: [], edges: [] });

              // onCreateWorkflow({
              //   name: ProcessNewCandidate.title,
              //   nodes: ProcessNewCandidate.nodes,
              //   edges: ProcessNewCandidate.edges,
              // });
            }}
          >
            <Icon>
              <CrossCircle />
            </Icon>
            <span>Stale Vacancy</span>
          </CardBox>
        </div>
      </div>
    </PageColumn>
  );
}

WorkflowCreatePanel.propTypes = {
  onCreateWorkflow: PropTypes.func,
  goBackToEdit: PropTypes.func,
};

WorkflowCreatePanel.defaultProps = {
  onCreateWorkflow: () => {},
  goBackToEdit: () => {},
};

export default WorkflowCreatePanel;
