import React, { memo, useCallback, useState } from 'react';
import EditNodeSlider from '../Sliders/EditNodeSlider/EditNodeSlider';
import { Button } from 'reactstrap';
import { Edit, Trash, Copy } from 'lucide-react';
import { NodeToolbar, useReactFlow, useStoreApi } from '@xyflow/react';

const getNextId = (nodes) => {
  if (nodes.length === 0) return '1';
  return String(Math.max(...nodes.map((node) => parseInt(node.id, 10))) + 1);
};

const handleDelete = (deleteElements, id) => {
  deleteElements({ nodes: [{ id }] });
};

const handleEdit = (value, id, nodes, setNodes, setIsSliderOpen) => {
  const updatedNodes = nodes.map((node) => {
    if (node.id === id) {
      return { ...node, data: { ...node.data, ...value } };
    }
    return node;
  });
  setNodes(updatedNodes);
  setIsSliderOpen(false);
};

export default memo(({ id, data, disableCopy }) => {
  const { setNodes, getNodes, getEdges, deleteElements } = useReactFlow();
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const nodes = getNodes();
  const edges = getEdges();

  const handleCopy = useCallback(() => {
    setNodes((currentNodes) => {
      const nodeToCopy = currentNodes.find((node) => node.id === id);

      if (nodeToCopy) {
        const newId = getNextId(currentNodes); // Generate a new ID for the copied node
        const newNode = {
          ...nodeToCopy,
          id: newId,
          position: {
            x: nodeToCopy.position.x + 50, // Slight offset to prevent overlap
            y: nodeToCopy.position.y + 50,
          },
          selected: true, // Select the new node
        };

        const updatedNodes = currentNodes.map((node) => ({
          ...node,
          selected: false,
        }));

        return [...updatedNodes, newNode]; // Add the new node to the nodes array
      }

      return currentNodes; // Return the current state if no node is found to copy
    });
  }, [id, setNodes]);

  return (
    <>
      <NodeToolbar isVisible={data.forceToolbarVisible || undefined} position={data.toolbarPosition}>
        <div className="button-group d-flex gap-1">
          <Button color="primary" size="sm" onClick={() => setIsSliderOpen(true)}>
            <Edit size={16} />
          </Button>
          {disableCopy ? null : (
            <Button color="secondary" size="sm" onClick={handleCopy}>
              <Copy size={16} />
            </Button>
          )}
          <Button color="danger" size="sm" onClick={() => handleDelete(deleteElements, id)}>
            <Trash size={16} />
          </Button>
        </div>
      </NodeToolbar>
      {isSliderOpen && (
        <EditNodeSlider
          isSliderOpen={isSliderOpen}
          closeSlider={() => setIsSliderOpen(false)}
          onSave={(value) => handleEdit(value, id, nodes, setNodes, setIsSliderOpen)}
          selectedNode={nodes.find((node) => node.id === id)}
          selectedEdges={edges.filter((edge) => edge.source === id)}
          onDelete={() => handleDelete(deleteElements, id)}
        />
      )}
    </>
  );
});
