import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import PermissionGroupDropdown from './PermissionGroupDropdown';
import PermissionsTable from './PermissionsTable';

function PermissionsMatrix(props) {
  const {
    schema: { title },
    idSchema: { $id: id },
    formData,
    onChange,
  } = props;
  let dropDownValue = { value: '', label: 'Custom Permissions' };

  if (formData.length === 1 && formData[0].toLowerCase().indexOf(':') === -1) {
    dropDownValue = { value: formData[0] };
  }

  return (
    <FormGroup>
      <Label for={id}>{title}</Label>
      <PermissionGroupDropdown
        selectedGroup={dropDownValue}
        setSelectedGroup={(a) => {
          onChange([a.value]);
        }}
      />
      {dropDownValue.value === '' && (
        <PermissionsTable
          permissions={formData}
          updateRoles={(role, checked) => {
            const updatedAuthorisers = checked
              ? [...formData, role]
              : formData.filter((permissions) => permissions !== role);

            onChange(updatedAuthorisers);
          }}
        />
      )}
    </FormGroup>
  );
}

PermissionsMatrix.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string,
  }),
  idSchema: PropTypes.shape({
    $id: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.arrayOf(PropTypes.string),
};

PermissionsMatrix.defaultProps = {
  schema: {},
  idSchema: {},
  formData: [],
};

export default PermissionsMatrix;
