import React from 'react';
import { getBezierPath, EdgeLabelRenderer, BaseEdge, useReactFlow } from '@xyflow/react';
import styled from 'styled-components';

const StyledDeleteButton = styled.button`
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid black;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;

  &:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  }
`;

const CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, selected }) => {
  const { setEdges } = useReactFlow();

  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const midX = (sourceX + targetX) / 2;
  const midY = (sourceY + targetY) / 2;

  const onEdgeClick = () => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
  };

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        {selected ? (
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${midX}px,${midY}px)`,
              fontSize: 12,
              pointerEvents: 'all',
            }}
            className="nodrag nopan"
          >
            <StyledDeleteButton onClick={onEdgeClick}>×</StyledDeleteButton>
          </div>
        ) : (
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${midX}px,${midY.y}px)`,
              background: '#108F75',
              padding: 3,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 6,
              fontSize: 8,
              fontWeight: 600,
              color: '#fff',
            }}
            className="nodrag nopan"
          >
            Yes
          </div>
        )}
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
