import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';
import { toast } from 'react-toastify';
import moment from 'moment';
import { schema, uiSchema } from '../../../../js/model/form-schemas/UserAdminSchema';
import { transformErrors } from '../../../../js/utils/validation-helper';
import { CancelButton, CreateButton } from '../../../Base/Buttons';
import { AccountSelectField } from '../../../Base/Account';
import { isNumber, isPassword } from '../../../../js/utils/validation';
import { createUser, updateUser } from '../../../../api/AccountAPI';
import { retryableAPICall } from '../../../../api/common-api-utils';
import PermissionsMatrix from '../../../Base/Forms/Custom/Permissions/PermissionsMatrix';
import { requestStatuses } from '../../../../js/constants/requestStatuses';
import { trimFormData } from '../../../../js/utils/general-utils';
import SingleDDSelect from '../../../Base/Forms/Custom/DDSelect/SingleDDSelect';

async function proxyCall(data, id) {
  if (id) return updateUser({ ...data, id });
  return createUser(data);
}

function UserAdminEditor({ isEditing, data, tenantId, roles, userId, totalAccounts, activeAccount, onSave, onCancel }) {
  const [isSaving, setIsSaving] = useState(false);
  const [clientData, setClientData] = useState({});

  const multiAccount = totalAccounts > 1;
  const isCurrentUser = userId === (data.id || '');

  useEffect(() => {
    const { id, name = '', email, restrictedUser, permissions = [], status } = data;
    let { accounts } = data;

    // if current user being edited
    if (userId === id) {
      // ignore active account
      accounts = accounts.filter(({ id: accountId }) => accountId !== activeAccount.activeAccountId);
    }

    // for single account - set account as active account
    if (!isEditing && !multiAccount) {
      accounts = [{ id: activeAccount.activeAccountId, name: activeAccount.activeAccountName }];
    }

    const [firstName, surname] = name.split(' ');

    const mappedData = {
      firstName: firstName.length ? firstName : undefined,
      surname,
      email,
      permissions: permissions.length ? [...permissions] : [...roles],
      restrictedUser,
      status,
      accounts: (accounts || []).map(({ id: accountId, name: accountName }) => ({ accountId, accountName })),
      // eslint-disable-next-line max-len
      defaultAccount: (accounts || [])
        .filter(({ default: def }) => def)
        .map(({ id: accountId, name: accountName }) => ({ accountId, accountName })),
    };

    setClientData((prevState) => ({ ...prevState, ...mappedData }));
  }, [data, userId, activeAccount, roles, isEditing, multiAccount]);

  useLayoutEffect(() => {
    [...document.querySelectorAll('.form-group')].forEach((elm) => {
      if (isCurrentUser && elm.classList.contains('default-account')) {
        // eslint-disable-next-line no-param-reassign
        elm.style.display = 'none';
      }
    });
  }, [isCurrentUser]);

  async function handleSave(formData) {
    setIsSaving(true);

    const trimmedData = trimFormData(formData);
    setClientData(trimmedData);

    const { defaultAccount, permissions } = trimmedData;
    let { accounts } = trimmedData;
    const [defAcc] = defaultAccount;
    let defAccId;

    if (defAcc) defAccId = defAcc.accountId;

    if (userId === data.id) {
      accounts = [
        ...accounts,
        { accountId: activeAccount.activeAccountId, accountName: activeAccount.activeAccountName },
      ];
    }

    let listOfPermissions = [];
    let permissionGroup = null;

    if (permissions.length === 1 && permissions[0].toLowerCase().indexOf(':') === -1) {
      permissionGroup = permissions[0];
    } else {
      listOfPermissions = permissions;
    }

    const serverData = {
      ...trimmedData,
      accountAccess: accounts.map(({ accountId, accountName }) => {
        let isDefault = false;

        if (isCurrentUser && data.accounts) {
          const accObj = data.accounts.find(({ id }) => id === accountId);
          if (accObj && accObj.default) isDefault = true;
        }

        if (accountId === defAccId) isDefault = true;

        return {
          default: isDefault,
          id: accountId,
          name: accountName,
        };
      }),
      password: trimmedData.password || '',
      restrictedUser: trimmedData.restrictedUser ? trimmedData.restrictedUser : false,
      permissions: listOfPermissions,
      permissionGroup,
    };
    delete serverData.passwordConfirm;
    delete serverData.accounts;
    delete serverData.defaultAccount;

    const resp = await retryableAPICall(() => proxyCall({ ...serverData, tenantId }, data.id));

    if (typeof resp === 'string') {
      if (resp === requestStatuses.ALREADY_EXISTS_ERROR) {
        toast.error('Unable to create user as this email address has already been used on the system');
      } else {
        toast.error(`Error ${isEditing ? 'updating' : 'creating'} user`);
      }
    } else {
      toast.success(`User successfully ${isEditing ? 'updated' : 'created'}`);

      // clean up response
      const {
        id,
        firstName,
        lastName,
        email,
        status,
        data: { accountAccess },
        lastActive,
      } = resp;

      onSave({
        id,
        email,
        name: `${firstName} ${lastName}`,
        accounts: accountAccess,
        permissions,
        lastActive: lastActive || moment().format('YYYY-MM-DD'),
      });
    }

    setIsSaving(false);
  }

  // don't want to edit password unless required
  let formSchema = !isEditing
    ? {
        ...schema,
        required: [...schema.required, 'password', 'passwordConfirm'],
      }
    : { ...schema };

  // not current user defaultAccount required
  formSchema = !isCurrentUser
    ? {
        ...formSchema,
        required: [...formSchema.required, 'defaultAccount'],
      }
    : { ...formSchema };

  // accounts must be required for multiAccounts
  formSchema = multiAccount
    ? {
        ...formSchema,
        required: [...formSchema.required, 'accounts'],
      }
    : { ...formSchema };

  const saveBtnText = isSaving ? 'Saving...' : `${isEditing ? 'Update' : 'Create'} User`;

  if (
    clientData && // 👈 null and undefined check
    Object.keys(clientData).length === 0 &&
    Object.getPrototypeOf(clientData) === Object.prototype
  ) {
    return null;
  }

  return (
    <Form
      fields={{
        accountSelect: AccountSelectField,
        permissionsMatrix: PermissionsMatrix,
        singleSelect: SingleDDSelect,
      }}
      formContext={{
        ignoreActiveAccount: isCurrentUser,
        roles: [...roles],
      }}
      formData={clientData}
      noHtml5Validate
      onChange={({ formData }) => {
        const { accounts, defaultAccount } = formData;

        if (accounts.length) {
          const [firstAccount] = accounts;

          if (!defaultAccount.length) {
            // initial default account set as first account in list
            setClientData({
              ...formData,
              defaultAccount: [firstAccount],
            });
          }
        }
      }}
      onSubmit={({ formData }) => handleSave(formData)}
      schema={formSchema}
      showErrorList={false}
      transformErrors={transformErrors}
      uiSchema={uiSchema}
      validate={(form, errors) => {
        const errs = { ...errors };

        const { accounts, phoneNumber, password, passwordConfirm, defaultAccount } = form;

        if (multiAccount) {
          if (accounts && !accounts.length) {
            errs.accounts.addError('At least one account required');
          }

          if (!isCurrentUser && (!defaultAccount || !defaultAccount.length)) {
            errs.defaultAccount.addError('Default account required');
          }

          if (accounts && accounts.length && defaultAccount && defaultAccount.length) {
            const [defAcc] = defaultAccount;
            const hasCorrespondingAcc = accounts.some(({ accountId }) => accountId === defAcc.accountId);

            if (!hasCorrespondingAcc) {
              errs.defaultAccount.addError('Default account must be a selected account');
            }
          }
        }

        if (password) {
          const isPWValidObj = isPassword(password);
          if (!isPWValidObj.valid) errs.password.addError(isPWValidObj.message);
        }

        if (passwordConfirm) {
          if (passwordConfirm !== password) {
            errs.passwordConfirm.addError('Password does not match');
          }
        }

        if (phoneNumber) {
          // makesure phone number is a number
          const number = phoneNumber.replace(/ /g, '');
          const isNumValidObj = isNumber(number);
          if (!isNumValidObj.valid) errs.phoneNumber.addError(isNumValidObj.message);
        }

        return errs;
      }}
    >
      <CreateButton disabled={isSaving} isLoading={isSaving} label={saveBtnText} type="submit" />
      <CancelButton action={onCancel} disabled={isSaving} label="Cancel" />
    </Form>
  );
}

UserAdminEditor.propTypes = {
  data: PropTypes.shape(),
  tenantId: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  userId: PropTypes.string,
  totalAccounts: PropTypes.number,
  activeAccount: PropTypes.shape({
    activeAccountId: PropTypes.string,
    activeAccountName: PropTypes.string,
  }),
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

UserAdminEditor.defaultProps = {
  data: {},
  roles: [],
  userId: null,
  totalAccounts: 0,
  activeAccount: {
    activeAccountId: undefined,
    activeAccountName: undefined,
  },
  isEditing: false,
  onSave: () => {},
  onCancel: () => {},
};

export default UserAdminEditor;
