import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactFlowProvider, useNodes, useEdges } from '@xyflow/react';
import HeaderPanel from './HeaderPanel';

import { PageColumn } from '../Dashboard';
import WorkflowWrapper from './DragAndDrop/WorkflowWrapper';

function WorkflowEditPanel({ goBackToEdit, workflowObj }) {
  const [isSaving, setIsSaving] = useState(false);

  return (
    <ReactFlowProvider>
      <PageColumn className="pt-3 d-flex flex-column">
        <HeaderPanel
          goBackToEdit={goBackToEdit}
          initialWorkflow={workflowObj}
          setIsSaving={setIsSaving}
          isSaving={isSaving}
          resetWorkflow={() => resetWorkflow(workflowObj)}
        />
        <WorkflowWrapper initialEdges={workflowObj.edges} initialNodes={workflowObj.nodes} isSaving={isSaving} />
      </PageColumn>
    </ReactFlowProvider>
  );
}

WorkflowEditPanel.propTypes = {
  goBackToEdit: PropTypes.func,
  workflowObj: PropTypes.shape(),
};

WorkflowEditPanel.defaultProps = {
  goBackToEdit: () => {},
  workflowObj: {},
};

export default WorkflowEditPanel;
