import React, { memo, useState } from 'react';
import { Handle, Position, NodeToolbar } from '@xyflow/react';
import ActionButtons from './ActionButtons';

import styled from 'styled-components';
import iconGetter from '../Icons';

const Container = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
`;

const Icon = styled.div`
  background-color: darkred;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  padding: 0.25rem;

  svg {
    margin: auto;
    fill: #fff;
  }
`;

const Text = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 8px;
  display: flex;
  flex-direction: column;
  color: #1a2e56;

  .title {
    font-size: 12px;
    font-weight: bold;
  }

  .status {
    text-transform: capitalize;
  }
`;

export default memo(({ id, data, setNodes, nodes, edges, updateNodes, isConnectable }) => {
  const IconComp = iconGetter(data.actionType);

  return (
    <>
      <ActionButtons id={id} data={data} />
      <Container>
        <Handle type="target" position={Position.Top} style={{ background: '#555' }} isConnectable={isConnectable} />
        <Icon>
          <IconComp />
        </Icon>
        <Text>
          <div className="title">{data.label}</div>
        </Text>
      </Container>
    </>
  );
});
