import React, { Fragment, useState } from 'react';
import Slider from '../../../../../../Base/Slider/Slider';
import { Confirmation } from '../../../../../../Base/Modal';
import { CreateButton, CancelButton } from '../../../../../../Base/Buttons';
import { Input } from 'reactstrap'; // Assuming you're using Reactstrap for the UI components
import Tippy from '@tippyjs/react';
import { Info } from 'lucide-react';
import styled from 'styled-components';

const Icon = styled.div`
  svg {
    margin: auto;
  }
`;

function SettingsSlider({ isSliderOpen, closeSlider, onSave, initialValues }) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [tempValues, setTempValues] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTempValues({
      ...tempValues,
      [name]: value,
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    onSave(tempValues);
    closeSlider();
  };

  const handleCancel = () => {
    setTempValues(values); // Revert to initial values on cancel
    closeSlider();
  };

  return (
    <Fragment>
      <Slider
        title="Settings"
        isOpen={isSliderOpen}
        toggleOpen={(isBgClick) => {
          if (isBgClick) {
            setShowConfirm(true);
          } else {
            closeSlider();
          }
        }}
        focusForm={false}
        width="100%"
        pauseNotificationsOnOpen
      >
        {/* Section 1: General */}
        <div className="section">
          <h3>General</h3>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <Input
              type="text"
              id="description"
              name="description"
              value={tempValues.description}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Section 2: Error Handling */}
        <div className="section mt-4">
          <h3>Error Handling</h3>
          <div className="form-group">
            <label htmlFor="maxRetries" className="d-flex">
              Max Retries
              <Tippy content="Only the owner can delete this" maxWidth="450px" theme="ats">
                <Icon>
                  <Info size={16} />
                </Icon>
              </Tippy>
            </label>
            <Input
              type="number"
              id="maxRetries"
              name="maxRetries"
              value={tempValues.maxRetries}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="redeliveryDelay">Redelivery Delay</label>
            <Input
              type="number"
              id="redeliveryDelay"
              name="redeliveryDelay"
              value={tempValues.redeliveryDelay}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="backOffMultiplier">Backoff Multiplier</label>
            <Input
              type="number"
              id="backOffMultiplier"
              name="backOffMultiplier"
              value={tempValues.backOffMultiplier}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <CreateButton className="mt-2" label={'Save'} floatRight action={handleSave} />
        <CancelButton className="mt-2 ms-2" floatRight action={handleCancel} />
      </Slider>
      <Confirmation
        content="Your data will be lost if you close the slider"
        show={showConfirm}
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={() => {
          setShowConfirm(false);
          handleCancel();
        }}
      />
    </Fragment>
  );
}

export default SettingsSlider;
