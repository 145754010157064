import styled from 'styled-components';
import { Fragment } from 'react';
import CheckboxCell from './CheckboxCell';

const convertlastKey = (lastKey) => {
  switch (lastKey) {
    case 'allow':
    case 'view':
    case 'read':
    case 'preview':
    case 'readonly':
      return 'view';
    case 'write':
    case 'create':
      return 'create';
    case 'update':
    case 'admin':
      return 'update';
    case 'delete':
      return 'delete';
    default:
      return false;
  }
};

export const buildNestedPermissionObject = (permissions) => {
  const groupedPermissions = {};

  // List of integrations
  const integrationsList = [
    'accessgrp',
    'fourth',
    'polaris',
    'rota',
    's4',
    'samesystem',
    'sap-nl',
    'ubeya',
    'peoplehr',
    'tamigo',
  ];

  // Recursive function to build the permission object
  const addPermissionToGroup = (group, pathArray, permission) => {
    const currentKey = pathArray[0];

    if (
      currentKey === 'cvsearch' ||
      currentKey === 'system' ||
      currentKey === 'sap' ||
      currentKey === 'smart' ||
      currentKey === 'flow'
    ) {
      return;
    }
    // Check if the current key is an integration
    const isIntegration = integrationsList.includes(currentKey);

    // If the current key is an integration, move it under 'integrations'
    const targetGroup = isIntegration ? group['integrations'] || (group['integrations'] = {}) : group;

    if (
      pathArray.length === 2 &&
      ['view', 'create', 'update', 'delete', 'read', 'write', 'allow', 'preview', 'readonly', 'admin'].includes(
        pathArray[1],
      )
    ) {
      const action = convertlastKey(pathArray[1]);

      if (action === false) {
        return;
      }

      if (!targetGroup[currentKey]) {
        targetGroup[currentKey] = {};
      }

      // Only add 'self' for non-integration permissions
      if (!isIntegration) {
        if (!targetGroup[currentKey]['self']) {
          targetGroup[currentKey]['self'] = {};
        }
        targetGroup[currentKey]['self'][action] = permission;
      } else {
        // For integrations, just add the action directly without 'self'
        targetGroup[currentKey][action] = permission;
      }
    } else if (pathArray.length === 3 || pathArray.length === 2) {
      const middleKey = pathArray[1];
      let action = pathArray[2] ? convertlastKey(pathArray[2]) : false;

      if (pathArray.length === 2) {
        action = 'view';
      }

      if (action === false) {
        return;
      }

      if (!targetGroup[currentKey]) {
        targetGroup[currentKey] = {};
      }
      if (!targetGroup[currentKey][middleKey]) {
        targetGroup[currentKey][middleKey] = {};
      }
      targetGroup[currentKey][middleKey][action] = permission;
    }
    // Handle deeper nesting recursively
    else if (pathArray.length > 3) {
      const action = convertlastKey(pathArray[pathArray.length - 1]);

      if (action === false) {
        return;
      }

      if (!targetGroup[currentKey]) {
        targetGroup[currentKey] = {};
      }
      addPermissionToGroup(targetGroup[currentKey], pathArray.slice(1), permission);
    }
  };

  // Iterate through each permission and add it to the appropriate group
  permissions.forEach((permission) => {
    const ammenedPermission = permission.name;

    if (ammenedPermission === 'chat:oviewer') {
      ammenedPermission = 'communication:sms';
    }
    addPermissionToGroup(groupedPermissions, ammenedPermission.split(':'), permission.name);
  });

  return groupedPermissions; // Return both the grouped permissions and the unique last keys
};

const CbCell = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const FeatureName = styled.div`
  text-transform: capitalize;

  &.rtw,
  &.gdpr,
  &.pt,
  &.sms {
    text-transform: uppercase;
  }
`;

export const renderPermissionCheckboxes = (featureObj, self, roles, updateRoles, currentRoles) => {
  return self ? (
    <>
      {['view', 'create', 'update', 'delete'].map((action) =>
        featureObj.self[action] ? (
          <CheckboxCell
            className="pm-grid-cell pm-grid-top-cell"
            key={action}
            id={`${featureObj.self}-${action}`}
            checked={roles?.includes(featureObj.self[action])}
            isDisabled={!currentRoles?.includes(featureObj.self[action])}
            onChange={(checked) => updateRoles(featureObj.self[action], checked)}
          />
        ) : (
          <CbCell className="pm-grid-cell pm-grid-top-cell" key={action} />
        ),
      )}
    </>
  ) : (
    <>
      <CbCell className="pm-grid-cell pm-grid-top-cell" />
      <CbCell className="pm-grid-cell pm-grid-top-cell" />
      <CbCell className="pm-grid-cell pm-grid-top-cell" />
      <CbCell className="pm-grid-cell pm-grid-top-cell" />
    </>
  );
};

export const renderSubPermissions = (feature, valuesWithoutSelf, roles, updateRoles, currentRoles) =>
  Object.entries(valuesWithoutSelf)
    .sort(([a], [b]) => a.localeCompare(b))
    .map(([section, sectionObj]) => {
      const idStr = `${feature}-${section}`;
      return (
        <Fragment key={idStr}>
          <FeatureName className={`${section} pm-grid-cell pm-grid-start-cell`}>{section}</FeatureName>
          {['view', 'create', 'update', 'delete'].map((action) =>
            sectionObj[action] ? (
              <CheckboxCell
                className="pm-grid-cell"
                key={action}
                id={`${idStr}-${action}`}
                checked={roles?.includes(sectionObj[action])}
                isDisabled={!currentRoles?.includes(sectionObj[action])}
                onChange={(checked) => updateRoles(sectionObj[action], checked)}
              />
            ) : (
              <CbCell className="pm-grid-cell" key={action} />
            ),
          )}
        </Fragment>
      );
    });
