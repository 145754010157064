import React, { useState, useReducer, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import moment from 'moment';
import { optionDataProp } from './SelectOption';
import DistanceFrom from './DistanceFrom';
import FilterOption, { selectedValueProp } from './FilterOption';
import { validatePostcode, findLabel, formatPostcode } from '../filter-utils';
import TagsFilter from './TagsFilter';
import AccountFilter from './AccountFilter';
import FilterCheckboxes from './FilterCheckboxes';
import UserFilter from './UserFilter';
import StatusFilter from './StatusFilter';
import FormQuestionFilter from './FormQuestionFilter';
import VacancyFilter from './VacancyFilter';
import PersonalityTestFilter from './PersonalityTestFilter';
import AssignedToFilter from './AssignedToFilter';

let FILTER_TYPES = {
  Account: [
    // eslint-disable-next-line object-curly-newline
    { id: 'in', condition: 'IN', label: 'Is one of', isMulti: true },
  ],
  Date: [
    { id: 'lt', condition: 'LT', label: 'Before' },
    { id: 'lte', condition: 'LTE', label: 'On or before' },
    { id: 'eq', condition: 'EQ', label: 'On' },
    { id: 'gt', condition: 'GT', label: 'After' },
    { id: 'gte', condition: 'GTE', label: 'On or after' },
  ],
  DateSpan: [{ id: 'lte', condition: 'LTE', label: 'In The Last' }],
  Dropdown: [
    { id: 'eq', condition: 'EQ', label: 'Is' },
    // eslint-disable-next-line object-curly-newline
    { id: 'in', condition: 'IN', label: 'Is one of', isMulti: true },
    { id: 'ne', condition: 'NE', label: 'Is not' },
    // eslint-disable-next-line object-curly-newline
    { id: 'nin', condition: 'NIN', label: 'Is not one of', isMulti: true },
  ],
  DropdownBinary: [
    { id: 'eq', condition: 'EQ', label: 'In' },
    { id: 'ne', condition: 'NE', label: 'Is not in' },
  ],
  PersonalityTest: [
    { condition: 'EQ', id: 'eq', label: 'Equal To' },
    { condition: 'LT', id: 'lt', label: 'Less Than' },
    { condition: 'GT', id: 'gt', label: 'Greater Than' },
  ],
  Status: [
    { id: 'eq', condition: 'EQ', label: 'Is' },
    // eslint-disable-next-line object-curly-newline
    { id: 'in', condition: 'IN', label: 'Is one of' },
    { id: 'in', condition: 'NIN', label: 'Not one of' },
  ],
  StatusUpdatedDate: [
    { id: 'eq', condition: 'EQ', label: 'On' },
    // eslint-disable-next-line object-curly-newline
    { id: 'gt', condition: 'GT', label: 'After' },
    { id: 'lt', condition: 'LT', label: 'Before' },
    { id: 'gte', condition: 'GTE', label: 'On or after' },
    { id: 'lte', condition: 'LTE', label: 'On of before' },
  ],
  Vacancy: [
    // eslint-disable-next-line object-curly-newline
    { id: 'in', condition: 'IN', label: 'Is one of', isMulti: true },
  ],
};

FILTER_TYPES = {
  ...FILTER_TYPES,
  Tags: [...FILTER_TYPES.Status],
  Users: [...FILTER_TYPES.Dropdown],
};

function getLabels(valueArr = [], dataArr = []) {
  return dataArr.reduce((acc, { value, label }) => (valueArr.includes(value) ? [...acc, label] : acc), []);
}

const SET_ERROR = 'SET_ERROR';
const UNSET_ERROR = 'UNSET_ERROR';

function errorReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ERROR:
      return {
        error: payload,
        isSet: true,
      };
    case UNSET_ERROR:
      return { ...state, isSet: false };
    default:
      return state;
  }
}

export default function FilterOptions({ type, currentFilter, handleApplyFilter, filterName }) {
  const { operation, value, data: optionData = [], location, field, fieldType, fieldOptions } = currentFilter;
  let initValue = value;

  if ((type === 'Date' || type === 'StatusUpdatedDate') && value && value[0]) {
    initValue = moment(value, 'YYYY-MM-DD');
  }

  const wrapperRef = useRef();
  const [checkedVal, setCheckedVal] = useState(operation);
  const [selectedValue, setSelectedValue] = useState(initValue);
  const [displayLabel, setDisplayLabel] = useState('');
  const [textValue, setTextValue] = useState(location);
  const [inputError, dispatchError] = useReducer(errorReducer, {
    error: 'select a value',
    isSet: false,
  });
  const [openCalDown, setOpenCalDown] = useState(true);
  const [filterField, setFilterField] = useState();
  const [appFilterValues, setAppFilterValues] = useState({
    question: { text: '', fieldType: '', fieldOptions: [] },
    answer: '',
  });

  useEffect(() => {
    if (wrapperRef.current) {
      const { top } = wrapperRef.current.getBoundingClientRect();
      setOpenCalDown(top < 500);
    }
  }, []);

  useEffect(() => {
    if (type === 'Application' && value) {
      const { question, answer } = value;

      setAppFilterValues((prevState) => ({
        ...prevState,
        question: {
          ...prevState.question,
          ...(question ? { text: question } : {}),
          fieldType,
          fieldOptions: [...fieldOptions] || [],
        },
        answer: answer || '',
      }));
    }
  }, [fieldOptions, fieldType, type, value]);

  if (!type) return null;

  const filterOpts = FILTER_TYPES[type] ? FILTER_TYPES[type] : [];

  function handleTextChange(val) {
    setTextValue(val);
    if (!selectedValue) setSelectedValue(15);
  }

  function isValid() {
    let isError = false;

    if (type === 'DistanceFrom' && (!textValue || !textValue.length || !validatePostcode(textValue))) {
      dispatchError({ type: SET_ERROR, payload: 'add a valid postcode' });
      isError = true;
    } else if (type === 'Date' && !selectedValue) {
      dispatchError({ type: SET_ERROR, payload: 'select a date' });
      isError = true;
    } else if (/Dropdown|DropdownBinary/.test(type) && !selectedValue) {
      dispatchError({ type: SET_ERROR, payload: 'select a value' });
      isError = true;
    } else if (type === 'Account') {
      if (selectedValue && !selectedValue.length) {
        dispatchError({ type: SET_ERROR, payload: 'select an account' });
        isError = true;
      } else if (!selectedValue) {
        isError = true;
      }
    } else if (type === 'Status') {
      if (!filterField) {
        dispatchError({ type: SET_ERROR, payload: 'select a status' });
        isError = true;
      }
    } else if (type === 'StatusUpdatedDate') {
      if (!filterField) {
        dispatchError({ type: SET_ERROR, payload: 'select a status' });
        isError = true;
      }
    } else if (type === 'Application') {
      const {
        question: { text, fieldType: fType },
        answer,
      } = appFilterValues;

      if (!text) {
        dispatchError({ type: SET_ERROR, payload: 'select a question' });
        isError = true;
      } else if (!answer) {
        dispatchError({
          type: SET_ERROR,
          payload: `${fType === 'array' ? 'select' : 'add'} an answer`,
        });
        isError = true;
      }
    } else if (type === 'PersonalityTest') {
      if (!selectedValue?.type) {
        dispatchError({ type: SET_ERROR, payload: 'select an score type' });
        isError = true;
      } else if (!selectedValue?.operation) {
        dispatchError({ type: SET_ERROR, payload: 'select an operation' });
        isError = true;
      } else if (!selectedValue?.value) {
        dispatchError({ type: SET_ERROR, payload: 'input a value' });
        isError = true;
      } else if (selectedValue?.operation !== 'EQ' && !/^\d+$/.test(selectedValue?.value)) {
        dispatchError({ type: SET_ERROR, payload: 'input a valid value' });
        isError = true;
      }
    }

    return !isError;
  }

  function handleSave() {
    dispatchError({ type: UNSET_ERROR });

    if (isValid()) {
      let newValue;
      let newOperation;
      let displayValue;
      let displayOperation;
      let newLocation;
      let newField;
      let newFieldType;
      let newFieldOpts;

      if (type === 'DistanceFrom') {
        const locationText = formatPostcode(textValue);
        newValue = selectedValue;
        newOperation = 'EQ';
        displayValue = `${selectedValue} miles from ${locationText}`;
        displayOperation = 'Is';
        newLocation = locationText;
      } else if (type === 'Application') {
        const { answer, question } = appFilterValues;

        newOperation = 'EQ';
        newValue = { answer, question: question.text };
        displayValue = `${question.text}, ${answer}`;
        displayOperation = 'Is';
        newFieldType = question.fieldType;
        newFieldOpts = [...question.fieldOptions];
      } else if (type === 'PersonalityTest') {
        newField = selectedValue.type;

        newValue = selectedValue.value;
        newOperation = selectedValue.operation;
        displayOperation = `${selectedValue.type === 'SERVICE_SCORE' ? 'Service' : 'Integrity'} Score is ${findLabel(
          filterOpts,
          selectedValue.operation,
        )}`;
        displayValue = selectedValue.value;

        if (selectedValue.type === 'SCORE_TYPE_OVERALL_SCORE') {
          const hollwegScores = {
            1: 'Low',
            2: 'Medium',
            3: 'High',
          };
          displayValue = hollwegScores[selectedValue.value] || selectedValue.value;
        }
      } else {
        newValue = selectedValue;
        newOperation = type === 'Account' || type === 'Vacancy' ? 'IN' : checkedVal;
        displayOperation = findLabel(filterOpts, newOperation);
        displayValue = selectedValue;

        if (/Tags|Account|Vacancy|Assigned|Users|Status|StatusUpdatedDate/.test(type)) displayValue = displayLabel;

        if (Array.isArray(displayValue)) displayValue = displayValue.join(', ');

        if (type === 'Date' || type === 'StatusUpdatedDate') {
          newValue = selectedValue.format('YYYY-MM-DD');
          displayValue = selectedValue.format('DD-MM-YYYY');
        }

        if (/DateSpan|DropdownBinary/.test(type)) {
          displayValue = optionData.reduce((acc, opt) => (opt.value === displayValue ? opt.label : acc), displayValue);
        }

        if (type === 'Status' && filterField) {
          newField = `${filterField}.currentStatus.status`;
        }
        if (type === 'StatusUpdatedDate' && filterField) {
          newField = `${filterField}.currentStatus.statusDateTime`;
        }
      }

      handleApplyFilter({
        value: newValue,
        operation: newOperation,
        displayValue,
        displayOperation,
        location: newLocation,
        field: newField,
        fieldType: newFieldType,
        fieldOptions: newFieldOpts,
      });
    }
  }

  const renderedFilterOptions = () => {
    switch (type) {
      case 'DistanceForm':
        return (
          <DistanceFrom
            onPostcodeChange={handleTextChange}
            onSelect={setSelectedValue}
            optionData={optionData}
            postcode={textValue}
            selectedValue={selectedValue || 15}
          />
        );
      case 'PersonalityTest':
        return (
          <PersonalityTestFilter
            onSelect={(vals) => {
              setSelectedValue(vals);
            }}
            selectedValue={selectedValue}
          />
        );
      case 'Tags':
        return (
          <TagsFilter>
            {({ optionData: optData }) =>
              filterOpts.map((opt) => (
                <FilterOption
                  key={opt.id}
                  {...opt}
                  checkedVal={checkedVal}
                  onRadioChange={setCheckedVal}
                  onSelect={(vals) => {
                    setDisplayLabel(getLabels(vals, optData));
                    setSelectedValue(vals);
                  }}
                  optionData={optData}
                  selectedValue={selectedValue}
                  type={type}
                />
              ))
            }
          </TagsFilter>
        );
      case 'Account':
        return (
          <AccountFilter>
            {({ optionData: optData }) => (
              <FilterCheckboxes
                onSelect={(vals) => {
                  setDisplayLabel(getLabels(vals, optData));
                  setSelectedValue(vals);
                }}
                optionData={optData.sort((a, b) => a.label.localeCompare(b.label))}
                selectedValue={selectedValue}
              />
            )}
          </AccountFilter>
        );
      case 'Vacancy':
        return (
          <VacancyFilter
            onSelect={(vals, optData) => {
              const ids = vals.map((val) => val.value);
              const labs = getLabels(ids, optData);
              setDisplayLabel(labs);
              setSelectedValue(ids);
            }}
            selectedValue={selectedValue}
          />
        );
      case 'Assigned':
        return (
          <AssignedToFilter
            onSelect={(val) => {
              setDisplayLabel(val.label);
              setSelectedValue(val.value);
              setCheckedVal('EQ');
            }}
            selectedValue={selectedValue}
          />
        );
      case 'Users':
        return (
          <UserFilter>
            {({ optionData: optData }) =>
              filterOpts.map((opt) => (
                <FilterOption
                  key={opt.id}
                  {...opt}
                  checkedVal={checkedVal}
                  onRadioChange={setCheckedVal}
                  onSelect={(vals) => {
                    setDisplayLabel(getLabels(vals, optData));
                    setSelectedValue(vals);
                  }}
                  optionData={optData}
                  selectedValue={selectedValue}
                  type={type}
                />
              ))
            }
          </UserFilter>
        );
      case 'Status':
        return (
          <StatusFilter field={field}>
            {({ optionData: optData, statusType }) =>
              filterOpts.map((opt) => (
                <FilterOption
                  key={opt.id}
                  {...opt}
                  checkedVal={checkedVal}
                  onRadioChange={setCheckedVal}
                  onSelect={(vals) => {
                    setDisplayLabel(getLabels(vals, optData));
                    setSelectedValue(vals);
                    setFilterField(statusType);
                  }}
                  optionData={optData}
                  selectedValue={selectedValue}
                  type={type}
                />
              ))
            }
          </StatusFilter>
        );
      case 'StatusUpdatedDate':
        return (
          <StatusFilter field={field}>
            {({ optionData: optData, statusType }) =>
              filterOpts.map((opt) => (
                <FilterOption
                  key={opt.id}
                  {...opt}
                  checkedVal={checkedVal}
                  onRadioChange={setCheckedVal}
                  onSelect={(vals) => {
                    setDisplayLabel(vals.format('DD-MM-YYYY'));
                    setSelectedValue(vals);
                    setFilterField(statusType);
                  }}
                  optionData={optData}
                  selectedValue={selectedValue}
                  type={type}
                />
              ))
            }
          </StatusFilter>
        );
      case 'Application':
        return (
          <FormQuestionFilter
            filterName={filterName}
            filterValues={appFilterValues}
            onChange={(filterCfg) => {
              setAppFilterValues(filterCfg);
            }}
          />
        );
      default:
        return filterOpts.map((opt) => (
          <FilterOption
            key={opt.id}
            {...opt}
            checkedVal={checkedVal}
            onRadioChange={setCheckedVal}
            onSelect={setSelectedValue}
            openCalDown={openCalDown}
            optionData={optionData}
            selectedValue={selectedValue}
            type={type}
          />
        ));
    }
  };

  return (
    <div ref={wrapperRef} className="filter-information-body">
      {renderedFilterOptions()}
      <div className="text-center mt-2">
        <Button className="btn-save-filter" onClick={handleSave} type="button">
          Save
        </Button>
        {inputError.isSet && (
          <p className="text-danger mt-2">{`Please ${inputError.error} before applying the filter`}</p>
        )}
      </div>
    </div>
  );
}

FilterOptions.propTypes = {
  type: PropTypes.string,
  currentFilter: PropTypes.shape({
    value: selectedValueProp,
    operation: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    data: optionDataProp,
    location: PropTypes.string,
    field: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    fieldType: PropTypes.string,
    fieldOptions: PropTypes.arrayOf(PropTypes.string),
  }),
  handleApplyFilter: PropTypes.func,
  filterName: PropTypes.string,
};

FilterOptions.defaultProps = {
  type: null,
  currentFilter: {},
  handleApplyFilter: () => {},
  filterName: null,
};
