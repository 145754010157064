import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { actionNodes, decisionNodes, delayNodes, exitNodes, inputNodes, notesNodes } from './NodeTypesLists';
import styled from 'styled-components';
import iconGetter from './Icons';

const StyledHeader = styled.div`
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-weight: bold;
  text-align: left;
`;

const StyledWarning = styled.div`
  padding-bottom: 0.5rem;
  font-style: italic;
  text-align: center;
`;

const Icon = styled.div`
  background-color: ${(props) => props.colour || 'black'};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  padding: 0.5rem;

  svg {
    margin: auto;
    fill: white;
  }
`;

function getColour(type) {
  switch (type) {
    case 'startNode':
      return 'green';
    case 'actionNode':
      return '#0a1551';
    case 'waitNode':
      return 'purple';
    case 'decisionNode':
      return 'orange';
    case 'annotationNode':
      return 'black';
    case 'endNode':
      return 'darkred';
    default:
      return 'black';
  }
}

function NodeAccordion({ nodes, header, isDisabled, warning }) {
  return (
    <>
      {header ? <StyledHeader>{header}</StyledHeader> : null}
      {warning ? <StyledWarning>{warning}</StyledWarning> : null}
      {nodes.map((node, index) => {
        const IconComp = iconGetter(node.actionType);

        return (
          <div
            key={index}
            className="dndnode default"
            draggable={!isDisabled}
            onDragStart={(event) => {
              event.dataTransfer.setData('application/reactflow', node.type);
              event.dataTransfer.setData('label', node.label);
              event.dataTransfer.setData('actionType', node.actionType);
            }}
            isDraggable={isDisabled ? 'false' : 'true'}
            style={{
              background: isDisabled ? 'grey' : 'inherit',
              cursor: isDisabled ? 'not-allowed' : 'pointer',
              gap: '10px',
              display: 'flex',
              border: '1px solid #ccc',
              boxSizing: 'border-box',
              borderRadius: '8px',
            }}
          >
            <Icon colour={getColour(node.type)}>{IconComp ? <IconComp /> : null}</Icon>
            {node.label}
          </div>
        );
      })}
    </>
  );
}

const NodeSelector = ({ nodes }) => {
  const [open, setOpen] = useState('');

  const hasInputNode = nodes.some((node) => node.type === 'startNode');

  const inputNodeList = nodes.filter((node) => node.type === 'startNode');
  const allowedActions =
    inputNodeList && inputNodeList.length > 0
      ? inputNodes.find((a) => {
          return a.actionType === inputNodeList[0].data.actionType;
        }).actions
      : [];

  return (
    <div style={{ position: 'absolute', zIndex: '2', minWidth: '20rem' }}>
      <Accordion
        flush
        open={open}
        toggle={(id) => {
          if (open === id) {
            setOpen();
          } else {
            setOpen(id);
          }
        }}
      >
        <AccordionItem>
          <AccordionHeader targetId={1}>Triggers</AccordionHeader>
          <AccordionBody accordionId={1}>
            <NodeAccordion
              nodes={inputNodes}
              isDisabled={hasInputNode}
              warning={hasInputNode ? 'Only one input node allowed' : null}
            />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId={2}>Nodes</AccordionHeader>
          <AccordionBody accordionId={2}>
            <NodeAccordion
              header="Actions"
              nodes={actionNodes.filter((a) => {
                return allowedActions.includes(a.actionType);
              })}
              warning={!hasInputNode ? <StyledWarning>Select trigger to see actions</StyledWarning> : null}
              isDisabled={!hasInputNode}
            />
            <NodeAccordion header="Delay" nodes={delayNodes} />
            <NodeAccordion header="Decision" nodes={decisionNodes} isDisabled={!hasInputNode} />
            <NodeAccordion header="Annotation" nodes={notesNodes} />
            <NodeAccordion header="Exit" nodes={exitNodes} />
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default NodeSelector;
