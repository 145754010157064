import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { IconSVG } from '../../../../Base/SVG';

export function validateWorkflow(nodes, edges) {
  const errors = [];

  // Helper functions to find edges related to a node
  const findIncomingEdges = (nodeId) => edges.filter((edge) => edge.target === nodeId);
  const findOutgoingEdges = (nodeId) => edges.filter((edge) => edge.source === nodeId);

  let startNodeCount = 0;
  let endNodeCount = 0;
  const nodeIds = new Set();

  nodes.forEach((node) => {
    // Check for duplicate node IDs
    if (nodeIds.has(node.id)) {
      errors.push(`Duplicate node ID found: ${node.id}`);
    } else {
      nodeIds.add(node.id);
    }

    if (node.type === 'annotationNode') {
      // Skip validation for annotation nodes
      return;
    }

    const incomingEdges = findIncomingEdges(node.id);
    const outgoingEdges = findOutgoingEdges(node.id);

    if (node.type === 'startNode') {
      startNodeCount += 1;
      // Start node must have at least one outgoing edge
      if (outgoingEdges.length === 0) {
        errors.push(`Start node '${node.data.label}' (ID: ${node.id}) does not have any outgoing edges.`);
      }
    } else if (node.type === 'endNode') {
      endNodeCount += 1;
      // End node must have at least one incoming edge
      if (incomingEdges.length === 0) {
        errors.push(`End node '${node.data.label}' (ID: ${node.id}) does not have any incoming edges.`);
      }
    } else {
      // Intermediate nodes must have both incoming and outgoing edges
      if (incomingEdges.length === 0) {
        errors.push(`Node '${node.data.label}' (ID: ${node.id}) does not have any incoming edges.`);
      }
      if (outgoingEdges.length === 0) {
        errors.push(`Node '${node.data.label}' (ID: ${node.id}) does not have any outgoing edges.`);
      }
    }

    // Check for disconnected nodes (except annotation nodes)
    if (incomingEdges.length === 0 && outgoingEdges.length === 0) {
      errors.push(`Node '${node.data.label}' (ID: ${node.id}) is disconnected from the workflow.`);
    }

    // Example of node-specific requirements, like decisionNode needing exactly two outgoing edges
    if (node.type === 'decisionNode') {
      if (outgoingEdges.length !== 2) {
        errors.push(`Decision node '${node.data.label}' (ID: ${node.id}) must have exactly two outgoing edges.`);
      }
    }
  });

  // Validate that there is exactly one start node
  if (startNodeCount !== 1) {
    errors.push(`There must be exactly one start node. Found ${startNodeCount}.`);
  }

  // Validate that there is at least one end node
  if (endNodeCount < 1) {
    errors.push(`There must be at least one end node. Found ${endNodeCount}.`);
  }

  // Validate that every edge's source and target node exists
  edges.forEach((edge) => {
    const sourceNode = nodes.find((node) => node.id === edge.source);
    const targetNode = nodes.find((node) => node.id === edge.target);
    if (!sourceNode) {
      errors.push(`Edge '${edge.id}' has a non-existent source node: ${edge.source}`);
    }
    if (!targetNode) {
      errors.push(`Edge '${edge.id}' has a non-existent target node: ${edge.target}`);
    }

    // Example of edge type validation, ensure edges connect to valid node types
    if ((edge.type === 'yesEdge' || edge.type === 'noEdge') && sourceNode?.type !== 'decisionNode') {
      errors.push(
        `Edge '${edge.id}' connects to a node type '${sourceNode?.type}' that does not support this edge type.`,
      );
    }
  });

  return errors;
}

function Validation({ errors }) {
  return (
    <UncontrolledDropdown disabled={errors.length === 0}>
      <DropdownToggle className="ci-link d-flex gap-1 mt-3 mx-2" tag={errors.length === 0 ? 'div' : 'a'}>
        {errors.length === 0 ? 'Valid' : 'Invalid'}
        <IconSVG name={errors.length === 0 ? 'Tick' : 'Cross'} />
      </DropdownToggle>
      <DropdownMenu
        disabled
        end
        modifiers={[
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top', 'right'],
            },
          },
        ]}
      >
        {errors.length === 0
          ? null
          : errors.map((error, index) => <DropdownItem onClick={() => console.log('hi')}>{error}</DropdownItem>)}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export function convertWorkflow(visualWorkflow) {
  const startNode = visualWorkflow.nodes.find((node) => node.type === 'startNode');

  const convertedWorkflow = {
    name: visualWorkflow.name,
    description: visualWorkflow.name,
    workflowType: 'CANDIDATE',
    workflowEventType: startNode?.data?.actionType,
    // What is going into here?
    trigger: {
      type: startNode?.data?.actionType,
      filters: [],
    },
    stages: [],
    errorHandler: {
      fragmentId: '1234', // Not sure what this is
      maxRetries: 3,
      redeliveryDelay: 5000,
      backOffMultiplier: 2.0,
    },
  };

  // Convert nodes to stages
  visualWorkflow.nodes.forEach((node, index) => {
    let stage = {
      type: convertNodeTypeToStageType(node.type),
      stageCoords: {
        x: node.position.x,
        y: node.position.y,
      },
      dimensions: {
        w: node.measured.width,
        h: node.measured.height,
      },
    };

    // Add config based on node type
    if (node.type === 'actionNode') {
      stage.config = {
        type: 'CANDIDATE',
        templateId: '648ad3a9fe2baf7fa937797c', // Example templateId
      };
    }

    convertedWorkflow.stages.push(stage);
  });

  return convertedWorkflow;
}

function convertNodeTypeToStageType(nodeType) {
  switch (nodeType) {
    case 'startNode':
      return 'getCandidate';
    case 'actionNode':
      return 'sendNotification';
    case 'endNode':
      return 'wait';
    default:
      return 'unknown';
  }
}

export default Validation;
