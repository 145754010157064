import React, { memo, useState } from 'react';
import { Handle, Position, NodeToolbar } from '@xyflow/react';
import EditNodeSlider from '../Sliders/EditNodeSlider/EditNodeSlider';
import ActionButtons from './ActionButtons';

import styled from 'styled-components';
import iconGetter from '../Icons';

const Container = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
`;

const Icon = styled.div`
  background-color: orange;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  padding: 0.25rem;

  svg {
    margin: auto;
    fill: #fff;
  }
`;

const Text = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 8px;
  display: flex;
  flex-direction: column;
  color: #1a2e56;

  .title {
    font-size: 12px;
    font-weight: bold;
  }

  .status {
    text-transform: capitalize;
  }
`;

export default memo(({ id, data, setNodes, nodes, edges, updateNodes, isConnectable }) => {
  const IconComp = iconGetter(data.actionType);

  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const handleEdit = (value) => {
    const updatedNodes = nodes.map((node) => {
      if (node.id === id) {
        return { ...node, data: { ...node.data, ...value } };
      }
      return node;
    });
    setNodes(updatedNodes);
    updateNodes(updatedNodes, edges);
    setIsSliderOpen(false);
  };

  const handleDelete = () => {
    const updatedEdges = edges.filter((edge) => !(edge.source === id || edge.target === id));
    setNodes(nodes.filter((node) => node.id !== id));
    updateNodes(
      nodes.filter((node) => node.id !== id),
      updatedEdges,
    );
    setIsSliderOpen(false);
  };

  return (
    <>
      <ActionButtons id={id} data={data} />
      <Handle type="target" position={Position.Top} />
      <Container>
        <Icon>
          <IconComp />
        </Icon>
        <Text>
          <div className="title">{data.label}</div>
          <div className="status">{data.condition}</div>
        </Text>
      </Container>
      <Handle
        type="source"
        position={Position.Bottom}
        id="yes"
        style={{ left: '25%', background: '#555' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="no"
        style={{ right: '25%', left: 'unset', background: '#555' }}
        isConnectable={isConnectable}
      />
      {isSliderOpen && (
        <EditNodeSlider
          isSliderOpen={isSliderOpen}
          closeSlider={() => setIsSliderOpen(false)}
          onSave={handleEdit}
          selectedNode={nodes.find((node) => node.id === id)}
          selectedEdges={edges.filter((edge) => edge.source === id)}
          onDelete={handleDelete}
        />
      )}
    </>
  );
});
